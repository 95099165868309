export default {
  name: "Input",
  data: () => ({
    labelClass: ""
  }),
  props: {
    pLabel: String,
    pRules: String,
    pName : String
  },
  methods: {
    handleInputBlur() {
      this.labelClass = ""
    },
    handleInputFocus() {
      this.labelClass = "primary--text"
    },
    getLabelClass(errors) {
      if (errors.length) {
        return "error--text text--darken-2"
      } else {
        return this.labelClass
      }
    }
  }
}